export class GlobalConstants {

    public static sources = [
        {
            src: 'https://streaming.radioagudo.com.br/radio/8000/vivo?1571154613',
            type: 'audio/aac'
        }
    ];

    public static api;
    static media: any;
    static play;
    static playerActive = false;
    static audioInfo: any;

}
