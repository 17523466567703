import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { AppRoutingModule, routes } from "./app.routing";

import { AppComponent } from "./app.component";
import { NavbarComponent } from "./shared/navbar/navbar.component";
import { FooterComponent } from "./shared/footer/footer.component";

import { HttpClientModule } from "@angular/common/http";
import { AudiosProvider } from "../providers/audios/audiosProvider";
import {
  faAddressBook,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBroadcastTower,
  faCalendar,
  faCalendarDay,
  faClipboardList,
  faCloud,
  faCloudRain,
  faCloudShowersHeavy,
  faCloudSun,
  faComment,
  faCross,
  faEnvelope,
  faFileAudio,
  faHeadphones,
  faLandmark,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMicrophone,
  faMobileAlt,
  faMusic,
  faNewspaper,
  faPlay,
  faPlayCircle,
  faPodcast,
  faPooStorm,
  faQuoteRight,
  faSmog,
  faSnowflake,
  faStar,
  faSun,
  faThermometerQuarter,
  faTimesCircle,
  faUser,
  faVideo,
  faWind,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faWhatsappSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  FaIconLibrary,
  FontAwesomeModule,
} from "@fortawesome/angular-fontawesome";
import { ObituaryProvider } from "../providers/obituary/obituaryProvider";
import { ScheduleProvider } from "../providers/schedule/scheduleProvider";
import { TemperatureProvider } from "../providers/temperature/temperatureProvider";
import { MatIconModule } from "@angular/material/icon";
import { ObituaryComponent } from "./obituary/obituary.component";
import { AllObituaryComponent } from "./obituary/all-obituary/all-obituary.component";
import { PipesModule } from "../pipes/pipes.module";
import { RemoveCaracteres } from "../pipes/removeCaracteres";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgMarqueeModule } from "ng-marquee";
import { AudiosComponent } from "./audios/audios.component";
import { ScheduleComponent } from "./schedule/schedule.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MailProvider } from "../providers/mail/mailProvider";
import { SnackbarComponent } from "./shared/snackbar/snackbar.component";
import { AboutComponent } from "./about/about.component";
import { MetaAndTitle } from "./shared/metaAndTitle/metaAndTitle";
import { AdsProvider } from "../providers/ads/adsProvider";
import { CalendarProvider } from "../providers/calendar/calendarProvider";
import { ShareIconsModule } from "ngx-sharebuttons/icons";
import { YoutubeComponent } from "./youtube/youtube.component";
import localePt from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import {MatTooltipModule} from '@angular/material/tooltip';
import { NewsProvider } from "src/providers/news/newsProvider";

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    NavbarComponent,
    FooterComponent,
    ObituaryComponent,
    AllObituaryComponent,
    AudiosComponent,
    ScheduleComponent,
    SnackbarComponent,

    YoutubeComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    NgbModule,
    HttpClientModule,
    MatListModule,
    MatButtonModule,
    FormsModule,
    ShareIconsModule,
    MatTooltipModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: "enabled",
    }),
    AppRoutingModule,
    InfiniteScrollModule,
    MatTabsModule,
    NgMarqueeModule,
    MatIconModule,
    MatInputModule,
    PipesModule,
    MatSnackBarModule,
    MatExpansionModule,    
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    AdsProvider,
    CalendarProvider,
    FooterComponent,
    ObituaryProvider,
    ScheduleProvider,
    TemperatureProvider,
    AudiosProvider,
    MetaAndTitle,
    MailProvider,
    NewsProvider,
    RemoveCaracteres,
    {
      provide: LOCALE_ID,
      useValue: "pt-BR",
    },
  ],
 
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faNewspaper,
      faVideo,
      faEnvelope,
      faMobileAlt,
      faPlay,
      faCloudShowersHeavy,
      faPooStorm,
      faArrowRight,
      faWind,
      faPooStorm,
      faPooStorm,
      faSnowflake,
      faSnowflake,
      faSnowflake,
      faSmog,
      faCloudRain,
      faCloudRain,
      faCloudRain,
      faSnowflake,
      faSnowflake,
      faSnowflake,
      faSnowflake,
      faCloudRain,
      faSnowflake,
      faWind,
      faSmog,
      faWind,
      faWind,
      faWind,
      faWind,
      faSnowflake,
      faCloud,
      faCloudSun,
      faSun,
      faSun,
      faStar,
      faCloudSun,
      faCloudShowersHeavy,
      faWind,
      faPooStorm,
      faCloudRain,
      faSnowflake,
      faCloudRain,
      faSnowflake,
      faCloudRain,
      faCloudRain,
      faSnowflake,
      faPooStorm,
      faSun,
      faAngleDoubleRight,
      faTimesCircle,
      faMusic,
      faThermometerQuarter,
      faHeadphones,
      faFileAudio,
      faArrowLeft,
      faAngleLeft,
      faUser,
      faAngleRight,
      faPlayCircle,
      faComment,
      faMicrophone,
      faCross,
      faQuoteRight,
      faBroadcastTower,
      faCalendar,
      faCalendarDay,
      faPodcast,
      faMapMarkerAlt,
      faMapMarkedAlt,
      faClipboardList,
      faLandmark,
      faInstagram,
      faWhatsappSquare,
      faFacebookSquare,
      faAddressBook
    );
  }
}
